<template>
  <div>
    <div class="contain1">
      <div class="code">
        <vue-qr ref="qrCode" :text="link" :size="200"></vue-qr>
        <!-- <img src="@/assets/image/icon120.png" alt="" /> -->
        邀请二维码
      </div>
      <div class="header_search">
        <div style="display: flex; align-items: center">
          <el-input v-model="link" placeholder="请输入关键字" disabled style="width: 500px"
            id="success_form_input"></el-input>
        </div>
        <div style="display: flex; align-items: center">
          <div class="search_line"></div>
          <div class="search_text" @click="copyLink()" ref="copy" data-clipboard-action="copy"
            data-clipboard-target="#success_form_input">
            复制链接
          </div>
        </div>
      </div>
    </div>
    <div class="richText">
      <div class="richText_title">内容规则</div>
      <div class="richText_line"></div>
      <div class="richText1" v-html="content"></div>
    </div>
    <div class="contain2">
      <div class="screen_contain">
        <div class="screen_item" :class="{ screen_item_active: current == item.name }" v-for="(item, index) in screen"
          :key="index" @click="changeScreen(item)">
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="contain2_line"></div>
      <div v-if="current == '邀请好友记录'">
        <el-table :data="list" style="width: 100%" header-cell-class-name="tabe_header">
          <el-table-column label="头像" width="140" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.user.avatar
          ? $imgURL + scope.row.user.avatar
          : icon52
          " alt="" class="touxaing" />
            </template>
          </el-table-column>
          <el-table-column prop="user.username" label="昵称" width="140" align="center">
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" align="right">
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table :data="list" style="width: 100%" header-cell-class-name="tabe_header">
          <el-table-column label="头像" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.user.avatar" alt="" class="touxaing" />
            </template>
          </el-table-column>
          <el-table-column prop="user.username" label="昵称" align="center">
          </el-table-column>
          <el-table-column label="消费金额（元）" align="center">
            <template>
              <div>0</div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="获得收益（元）" align="center">
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" align="center">
          </el-table-column>
        </el-table>
      </div>

      <!-- <div class="pagination_contain">
        <div>共658项数据</div>
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div> -->
      <div style="height: 20px"></div>
    </div>
  </div>
</template>

<script>
import { get } from "/utils/request";
import VueQr from "vue-qr";
import icon52 from "@/assets/image/icon52.png";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      link: "",
      content:
        "<p>dsg文字描述的事发生的故事带你该功能工人如果哈哈哈如何让二号dsg文字描述的事发生的故事带你该功能工人如果哈哈哈如何让二号dsg文字描述的事发生的故事带你该功能工人如果哈哈哈如何让二号dsg文字描述的事发生的故事带你该功能工人如果哈哈哈如何让二号dsg文字描述的事发生的故事带你该功能工人如果哈哈哈如何让二号</p>",
      current: "",
      screen: [
        { id: 1, name: "邀请好友记录", key: "" },
        { id: 2, name: "邀请奖励记录", key: "" },
      ],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          img: "https://img2.baidu.com/it/u=1814561676,2470063876&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
        },
      ],
      list: [],
      qrCodeUrl: "",
      icon52: icon52,
      copyBtn: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeScreen(item) {
      this.current = item.name;
      this.list = [];
      this.getList();
      this.copyBtn = new this.clipboard(this.$refs.copy);
    },
    getList() {
      get("/invite/list").then((res) => {
        console.log(res);
        res.data.map((item) => {
          console.log("item.user", item.user);
          item.user["avatar"] = item.user.avatar
            ? this.$imgURL + item.user.avatar
            : this.icon52;
        });
        this.list = res.data ?? [];
      });
      get("/article", { identifier: "yqgz" }).then((res) => {
        console.log("返回的富文本---", res);
        this.content = res.content;
      });
      get("info/user").then((res) => {
        // res.data.invite_code
        console.log(res);
        this.link = `${this.$invitList}?invite_code=${res.data.invite_code}`;
        // this.link = `https://www.baidu.com/`;
      });
    },
    copyLink() {
      // // navigator.clipboard.writeText(this.link);
      // // this.$message.success("复制成功");
      let _this = this;
      // let clipboard = _this.copyBtn;
      // console.log('clipboard',clipboard);
      // clipboard.on("success", function () {
      //   _this.$message({
      //     /*这是使用了element-UI的信息弹框*/ message: "复制成功！",
      //     type: "success",
      //   });
      // });
      // clipboard.on("error", function () {
      //   _this.$message({
      //     message: "复制失败，请手动选择复制！",
      //     type: "error",
      //   });
      // });
//       let qrCodeUrl = this.qrCodeUrl;
// console.log('qrCodeUrlqrCodeUrlqrCodeUrlqrCodeUrl',qrCodeUrl);
      this.$copyText(this.link).then(
        (e) => {
          _this.$message.success('复制成功!')
          console.log(e);
        },
        (err) => {
          _this.$message.error('复制失败,请重试!')
          // Message('复制失败,请重试!')
          console.log(err);
        }
      );
    },
    qrCodeCallback(url) {
      this.qrCodeUrl = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.contain1 {
  width: 1400px;
  height: 611px;
  background: url("../../assets/image/icon40.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 0 0;
  box-sizing: border-box;
  margin: 20px auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 300px 0 0;
  box-sizing: border-box;

  .code {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.7px;

    img {
      width: 135px;
      height: 135px;
      margin-bottom: 15px;
    }
  }

  .header_search {
    width: 696px;
    height: 52px;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    ::v-deep .el-input__inner {
      border: none !important;
      padding-left: 0;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
      background: #ffffff;
      cursor: auto;
    }

    .search_line {
      width: 1px;
      height: 25px;
      background: #efefef;
    }

    .search_text {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #23dc5a;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}

.richText {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 20px;
  padding: 20px;
  box-sizing: border-box;

  .richText_title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }

  .richText_line {
    height: 1px;
    background: #e0e0e0;
    margin: 20px 0;
  }

  .richText1 {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.7px;
  }
}

.contain2 {
  width: 1400px;
  // height: 2357px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 50px;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .screen_contain {
    display: flex;
    align-items: center;

    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }

      .line {
        width: 72px;
        height: 3px;
        background: transparent;
      }
    }

    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        margin-bottom: 13px;
      }

      .line {
        width: 108px;
        height: 3px;
        background: #23dc5a;
      }
    }
  }

  .contain2_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }

  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;

      .el-icon-arrow-left {
        font-size: 24px;
      }
    }

    ::v-deep .btn-next {
      width: 46px;
      height: 46px;

      .el-icon-arrow-right {
        font-size: 24px;
      }
    }

    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}

::v-deep .tabe_header {
  background: #f6f7f8 !important;
}

.touxaing {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

::v-deep .el-table .el-table__cell.is-right {
  padding-right: 56px;
  box-sizing: border-box;
}
</style>